<template>
  <Modal v-model="productPublicityModal" width="1200" :footer-hide="modalType==='show'">
    <p slot="header" class="text-center">{{modalType==='create'?'项目结果公示':'公示内容'}}</p>
    <Form
      ref="publicityInfoModel"
      :model="publicityInfoModel"
      autocomplete="on"
      name="oohforce-device-add"
      id="oohforce-device-add"
      label-position="top"
    >
      <Row :gutter="8" class="m-b-10" >
        <i-col span="8" v-if="modalType==='create'">
          <FormItem label="公示类型">
            <RadioGroup v-model="publicityInfoModel.publicType" size="small">
              <Radio
                v-for="(item, index) in publicTypeArray"
                :key="index"
                :label="item.id"
                >{{ item.name }}</Radio
              >
            </RadioGroup>
          </FormItem>
        </i-col>

        <i-col span="8" v-if="modalType==='create'">
          <FormItem label="公示时间">
            <DatePicker
              size="small"
              transfer
                type="datetimerange"
                format="yyyy-MM-dd HH:mm"
              @on-change="scheduleChange"
              :show-week-numbers="true"
              v-model="publicSchedule"
              style="width: 100%"
              placeholder="选择公示时间"
            ></DatePicker>
          </FormItem>
        </i-col>
        <i-col span="24" v-if="modalType==='create'">
          <FormItem label="客户">
            <CheckboxGroup v-model="chkOrderNos" size="small">
              <Checkbox
                v-for="(item, index) in businessArray"
                :key="index"
                :label="item.orderId"
                >{{ item.userName }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
        </i-col>

        <i-col span="24"  v-if="modalType==='create'">
          <FormItem label="附件">
            <Upload
              ref="upload"
              :with-credentials="true"
              :action="action"
              :headers="uploadHeaders"
              :data="data"
              :show-upload-list="false"
              :format="format"
              :max-size="maxSize"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleExceedMaxsize"
              multiple
              :on-success="handleFileUploadSuccess"
              type="drag"
            >
              <div style="padding: 20px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>点击或拖拽文件到这里进行上传</p>
              </div>
              <!-- <slot name="upload_btn">
        <div>
          <a >添加文件并上传</a>
        </div>
      </slot> -->
            </Upload>

            <div class="file-container">
              <div v-if="uploadFiles.length > 0">
                <h4 class="text-green p-t-10">最新上传文件</h4>
                <Row v-for="(file, i) in uploadFiles" :key="i">
                  <template v-if="file.status === 'finished'">
                    <i-col span="16">
                      {{ file.name }}
                    </i-col>
                    <i-col span="8">
                      <a
                        href="javascript:void(0)"
                        @click="handleDownload(file.response)"
                        >下载</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="m-l-5"
                        style="color: #ef4f4f"
                        @click="handleRemove(file.response,i)"
                        >删除</a
                      >
                    </i-col>
                  </template>
                  <template v-else>
                    <i-col span="12">
                      <Progress
                        v-if="file.showProgress"
                        :percent="file.percentage"
                      ></Progress>
                      <span v-else>{{ file.name }}</span>
                    </i-col>
                    <!-- <i-col span="12">
                      <i-button
                        icon="ios-trash"
                        class="m-l-5"
                        type="error"
                        @click="handleRemove(file)"
                        >删除</i-button
                      >
                    </i-col> -->
                  </template>
                </Row>
              </div>
<!--
              <div v-if="existsFile.length > 0">
                <Divider dashed>已有文件</Divider>
                <i-table
                  stripe
                  :data="existsFile"
                  :columns="fileColumns"
                ></i-table>
              </div> -->
            </div>
            <!-- 上传描述，备注信息 -->
            <div class="tips m-l-10">
              <ul>
                <li>您只能上传后缀名为 {{ format.toString() }} 格式的文件</li>
                <li>文件大小不能超过 {{ maxSize / 1024 }} MB</li>
              </ul>
            </div>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="公示内容">

        <TinyEditor :id="tinyId" v-model="publicityHtml" :disabled="modalType==='show'"></TinyEditor>
            <!-- <Input
              v-model="publicityInfoModel.content"
              type="textarea"
              placeholder="公示信息说明"
            /> -->
          </FormItem>
        </i-col>
      </Row>
    </Form>

    <div slot="footer">
      <Button type="text" class="m-r-5" @click="productPublicityModal = false"
        >关闭</Button
      >
      <Button type="primary" @click="handleConfirmPublicity">确定</Button>
    </div>
  </Modal>
</template>

<script>
import TinyEditor from '@/components/common/TinyEditor'
import { HTMLEncode } from '@/utils'
import { downloadFileRequest } from '@/utils/download'
import { listSinInBusiness, publicProject } from '@/api/invest/invest'
import { signurlByAttach, removeFileAttach } from '@/api/resource/endpoint'
import { getStorage } from '@/utils/storage'
import { ParseDateTime } from '@/utils/dateFormat'
export default {
  components: {
    TinyEditor
  },
  props: {
    deviceArray: {
      type: Array
    }
  },
  data () {
    return {
      tinyId: 'tiny-' + (new Date().getTime()),
      publicSchedule: [],
      productPublicityModal: false,
      publicTypeArray: [
        {
          id: '5',
          name: '入围公示'
        },
        {
          id: '7',
          name: '招商结果公示'
        }
      ],
      publicityInfoModel: {
        businessNames: '',
        endDate: '',
        orderNos: '',
        investId: null,
        publicType: '5',
        startDate: null,
        content: '',
        fileList: []
      },
      publicityHtml: '',
      businessArray: [],
      chkOrderNos: [],

      data: { code: 1 },
      uploadList: [],
      action: process.env.VUE_APP_API_URL_V2 + '/ooh-resource/oss/endpoint/put-file-attach',
      visible: false,
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      format: ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'],
      maxSize: 204800,
      uploadFiles: [],

      modalType: 'create',
      publicityInfo: null

    }
  },
  mounted () {},
  methods: {
    showModal (type, publicityInfo) {
      this.modalType = type

      switch (this.modalType) {
        case 'show':
          this.publicityHtml = publicityInfo.content
          break
        case 'create':
          this.publicityHtml = ''
          this.publicityInfoModel.investId = this.investmentId
          listSinInBusiness({ investId: this.investmentId }).then((res) => {
            this.businessArray = res.filter((x) => x.orderStatus !== 70)
          })
          break
      }

      this.productPublicityModal = true
    },
    scheduleChange () {
      const shcedule = this.publicSchedule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.publicityInfoModel.startDate = ''
        this.publicityInfoModel.endDate = ''
      } else {
        this.publicityInfoModel.startDate = ParseDateTime(shcedule[0])
        this.publicityInfoModel.endDate = ParseDateTime(shcedule[1])
      }
    },

    hideModal () {
      this.productPublicityModal = false
    },

    handleExceedMaxsize (file) {
      this.$Notice.warning({
        title: '已经超过了文件大小限制',
        desc: '文件  ' + file.name + ' 过大，不能超过' + this.maxSize + 'Kb'
      })
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件类型不正确',
        desc: '文件  ' + file.name + ' 的类型只能是' + this.format.toString()
      })
    },
    handleFileUploadSuccess (response, file) {
      if (response.success) {
        this.uploadFiles.push(file)
      }
    },
    handleDownload (file) {
      signurlByAttach({ attachId: file.data.id }).then(res => {
        downloadFileRequest(res.data, { }, file.data.originalName)
      })
    },
    handleRemove (file, index) {
      this.uploadFiles.splice(index, 1)
      removeFileAttach({ attachId: file.data.id })
    },
    // 确定公示
    handleConfirmPublicity () {
      const that = this
      if (that.chkOrderNos.length) {
        that.publicityInfoModel.orderNos = that.chkOrderNos.toString()
        that.publicityInfoModel.businessNames = that.businessArray.filter(x => that.chkOrderNos.includes(x.orderId)).map(item => {
          return item.userName
        }).toString()
      } else {
        this.$Notice.error({ desc: '请选择客户' })
        return
      }

      if (that.uploadFiles.length) {
        that.publicityInfoModel.fileList = JSON.stringify(that.uploadFiles.map(file => {
          return { fileName: file.response.data.originalName, filePath: file.response.data.link }
        }))
      }

      that.publicityInfoModel.content = HTMLEncode(this.publicityHtml)

      publicProject(that.publicityInfoModel).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          that.hideModal()
          this.$store.commit('set_investment_update', new Date())
        }
      })
    }
  },
  watch: {},
  computed: {
    investmentId () {
      return this.$store.state.investmentManage.investmentId
    }
  }
}
</script>
