<template>
  <div>
    <Modal v-model="deviceListModal" width="1200" footer-hide>
      <p slot="header" class="text-center">租赁空间资产列表</p>
      <Table
        size="small"
        :columns="columnDevicedata"
        :data="deviceArray"
        stripe
      ></Table>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="deviceListModal = false"
          >关闭</Button
        >
        <!-- <Button type="primary" @click="handleCreateInvestemnt">确定</Button> -->
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
      deviceListModal: false,
      deviceArray: [],
      columnDevicedata: [
        {
          title: '编号',
          key: 'innerCode',
          render: (h, params) => {
            return h('span', params.row.device.innerCode)
          }
        },
        {
          title: '类型',
          align: 'center',
          key: 'devicemodelName',
          render: (h, params) => {
            return h('span', params.row.device.devicemodelName)
          }
        },
        {
          title: '站点',
          align: 'center',
          key: 'stationName',
          render: (h, params) => {
            return h('span', params.row.device.stationName)
          }
        },
        {
          title: '楼层',
          align: 'center',
          key: 'floor',
          render: (h, params) => {
            return h('span', params.row.device.floor)
          }
        },
        {
          title: '位置',
          align: 'center',
          key: 'positionName',
          render: (h, params) => {
            return h('span', params.row.device.positionName)
          }
        },
        {
          title: '面积（㎡）',
          align: 'center',
          key: 'area',
          render: (h, params) => {
            return h('span', params.row.device.area)
          }
        },
        {
          title: '经营方',
          align: 'center',
          ellipsis: true,
          tooltip: true,
          key: 'manageUnitName',
          render: (h, params) => {
            return h('span', params.row.device.manageUnitName)
          }
        },

        {
          title: '通电',
          align: 'center',
          key: 'electric',
          render: (h, params) => {
            return h('span', params.row.device.electric ? '是' : '否')
          }
        },
        {
          title: '通水',
          align: 'center',
          key: 'waterSupply',
          render: (h, params) => {
            return h('span', params.row.device.waterSupply ? '是' : '否')
          }
        },
        {
          title: '通气',
          align: 'center',
          key: 'gasSupply',
          render: (h, params) => {
            return h('span', params.row.device.gasSupply ? '是' : '否')
          }
        },
        {
          title: '消防验收',
          align: 'center',
          key: 'fireApproval',
          render: (h, params) => {
            return h('span', params.row.device.fireApproval ? '是' : '否')
          }
        }
      ]
    }
  },
  mounted () {},
  methods: {
    showModal (deviceArray) {
      this.deviceArray = deviceArray
      this.deviceListModal = true
    }
  },
  watch: {},
  computed: {}
}
</script>
