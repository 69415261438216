<template>
  <div>
    <Row v-if="allowEdit">
      <i-col span="24" class="m-b-5">
        <a style="float: right; line-height: 30px" @click="handleSettingPhoto"
          >+新上传主图</a
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnImgdata"
      :data="coverImages"
      stripe
    ></Table>
    <!-- <Row class="m-b-10">
      <i-col span="24">
        <div
          class="demo-upload-list"
          v-for="item in coverImages"
          :key="item.id"
        >
          <template>
            <img :src="item.filePath + '?x-oss-process=image/resize,w_200'" />
            <div class="demo-upload-list-cover">
              <Icon
                type="ios-eye-outline"
                @click.native="handleView(item.filePath)"
              ></Icon>
              <Icon
                type="ios-trash-outline"
                @click.native="handleRemove(item.id)"
              ></Icon>
            </div>
          </template>
        </div>
      </i-col>
    </Row> -->

    <SettingPhoto
      ref="settingPhoto"
      :investId="investId"
      @on-success="loadInvestCoverImages"
    />
  </div>
</template>

<script>
import SettingPhoto from './SettingPhoto'
import { getFileList, deleteFile } from '@/api/invest/file'
export default {
  components: {
    SettingPhoto
  },
  props: {
    allowEdit: {
      type: Boolean,
      default: false
    },
    investId: {
      type: Number
    }
  },
  data () {
    return {
      coverImages: [],
      columnImgdata: [
        {
          title: '主图名称',
          key: 'fileName'
        },
        {
          title: '图片预览',
          key: 'name',
          render: (h, params) => {
            return h('div', [
              h('img', {
                domProps: {
                  src:
                    params.row.filePath +
                    '?x-oss-process=image/resize,w_64'
                },
                on: {
                  click: () => {
                    this.handleView(params.row.filePath)
                    // const postData = {
                    //   startIndex: 0,
                    //   imgList: params.row.taskitemFiles
                    // }
                    // this.sortIndex = 0
                    // this.fileName = params.row.domainName
                    // this.$refs.imgLightBox.init(postData)
                    // this.visiblePreview = true
                  }
                },
                style: {
                  width: '64px',
                  cursor: 'pointer'
                }
              })
            ])
          }
        },

        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const removeButton = h(
              'a',
              {
                style: {
                  marginRight: '5px',
                  color: 'red'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要移除【 ' + params.row.fileName + '】？</p>',
                      loading: true,
                      onOk: () => {
                        deleteFile({ fileId: params.row.id })
                          .then((res) => {
                            this.loadInvestCoverImages()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '移除'
            )

            return h('div', [this.allowEdit ? removeButton : '-'])
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    this.loadInvestCoverImages()
  },
  methods: {
    // 新加图片
    handleSettingPhoto () {
      this.$refs.settingPhoto.showModal()
    },

    // 删除图片
    handleRemove (id) {},
    handleView (fileUrl) {
      window.open(fileUrl)
    },
    // 加载已经上传的图片信息
    loadInvestCoverImages () {
      getFileList({ category: 1, keyId: this.investId }).then((res) => {
        this.coverImages = res
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>
.demo-upload-list {
  display: inline-block;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
