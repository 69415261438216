<template>
  <div>
    <Row >
      <i-col span="24" class="m-b-5">
        <a style="float: right; line-height: 30px" @click="showProductPublicityModal"
          >+新增项目公示</a
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnSpacedata"
      :data="tableData"
      stripe
    ></Table>

    <publicityModal ref="productPublic"></publicityModal>
  </div>
</template>

<script>
import publicityModal from './publicity.vue'

import { listInvestPublic } from '@/api/invest/invest'
import { downloadFileRequest } from '@/utils/download'

export default {
  components: {
    publicityModal
  },
  props: {
    investId: {
      type: Number
    }
  },
  data () {
    return {
      tableData: [],
      columnSpacedata: [
        {
          title: '公示类型',
          key: 'publicType',
          render: (h, params) => {
            var str = ''
            switch (params.row.publicType) {
              case 5:
                str = '入围公示'
                break
              case 7:
                str = '招商结果公示'
                break
              default:
                str = '未知'
            }

            return h('span', str)
          }
        },
        {
          title: '公示开始时间',
          key: 'startDate'
        },
        {
          title: '公示结束时间',
          key: 'endDate'
        },
        { title: '客户名称', key: 'businessNames' },

        {
          title: '附件',
          key: 'fileList',
          render: (h, params) => {
            const result = []
            const fileList = params.row.fileList ? JSON.parse(params.row.fileList) : []
            fileList.forEach((item) => {
              result.push(
                h('p', [
                  h(
                    'a',
                    {
                      style: {
                        marginRight: '5px'
                      },
                      on: {
                        click: () => {
                          downloadFileRequest(item.filePath, {}, item.fileName)
                        }
                      }
                    },
                    item.fileName
                  )
                ])
              )
            })
            return h('div', result)
          }
        },
        { title: '公示发起时间', key: 'createTime' },
        { title: '公示发起人', key: 'userName' },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$refs.productPublic.showModal('show', params.row)
                  }
                }
              },
              '公示内容'
            )
            return h('div', [detailButton])
          }
        }
      ]
    }
  },
  created () {
    this.reloadpublicityRecordArray()
  },
  mounted () {},
  methods: {
    // 新发布
    showProductPublicityModal () {
      this.$refs.productPublic.showModal('create', null)
    },
    // 加载发起过的招商记录
    reloadpublicityRecordArray () {
      listInvestPublic({ investId: this.investId }).then((res) => {
        this.tableData = res
      })
    }
  },
  watch: {
    beginUpdate () {
      this.reloadpublicityRecordArray()
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
