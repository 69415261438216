<template>
  <Modal v-model="showConfirm" width="400" title="上传商品主图"  :mask-closable="false">
      <Row class="m-b-10">
        <i-col span="24">
          <div class="demo-upload-list" v-for="(item,index) in uploadFiles" :key="index">
            <template v-if="item.status === 'finished'">
                <img :src="item.response.data.link + '?x-oss-process=image/resize,w_100'">
                <div class="demo-upload-list-cover">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.response.data.link)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                </div>
            </template>
            <template v-else>
                <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>
          <Upload
            ref="upload"
            :headers="headers"
            :action="uploadUrl"
            :show-upload-list="false"
            :on-success="handleUploadSuccess"
            :format="['jpg','jpeg','png']"
            :max-size="5120"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            multiple
            type="drag"
            style="display: inline-block;width:58px;">
            <div style="width: 58px;height:58px;line-height: 58px;">
                <Icon type="ios-camera" size="20" color="#2d8cf0"></Icon>
            </div>
          </Upload>
        </i-col>
      </Row>
      <Row>
        <i-col span="24" >
          <p class="remark">tips: 只支持图片上传，图片上传格式为：jpg、jpeg、 png，图片大小不超过5M。</p>
        </i-col>
      </Row>

      <div slot="footer">
      <Button type="text" @click="showConfirm = false">取消</Button>
      <i-button  :disabled="uploadFiles.length===0" type="primary" @click="handleAdd">确认</i-button>
    </div>

      <Spin fix size="large" v-if="spinShow">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>数据保存中...</div>
      </Spin>
  </Modal>
</template>

<script>
import { getStorage } from '@/utils/storage'
import { uploadInvestFile } from '@/api/invest/file'

export default {
  props: {
    investId: {
      type: Number
    }
  },
  data () {
    return {
      showConfirm: false,
      spinShow: false,

      saveBean: {
        investId: null,
        photos: null
      },

      uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-resource/oss/endpoint/put-file`,
      headers: {
        'ooh-auth': getStorage('ooh-token')
      }, // 上传组件设置协议头
      uploadFiles: []

      // serviceItems: [],
      // checkServiceItem: []
    }
  },
  mounted () {
    // const query = {
    //   publisherId: this.$store.getters.token.userInfo.publisherId
    // }
    // getCloudServiceItem(query).then(res => {
    //   this.serviceItems = res
    // })
    // this.uploadFiles = this.$refs.upload.fileList
  },
  methods: {
    showModal () {
      this.showConfirm = true
      this.$refs.upload.fileList = []
      this.uploadFiles = []
    },
    handleAdd () {
      this.spinShow = true

      uploadInvestFile({
        investId: this.investId,
        fileList: JSON.stringify(this.uploadFiles.map(x => {
          return {
            fileName: x.response.data.originalName,
            filePath: x.response.data.link
          }
        }))
      }).then(res => {
        this.$Notice.success({ desc: '新商品主图上传成功！', title: '操作成功' })
        this.spinShow = false
        this.showConfirm = false
        this.$emit('on-success')
      })
    },
    handleView (fileUrl) {
      window.open(fileUrl)
    },
    handleRemove (file) {
      const fileList = this.$refs.upload.fileList
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1)
      this.uploadFiles = JSON.parse(JSON.stringify(fileList))
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件格式错误',
        desc: '上传文件： ' + file.name + ' 格式异常, 请选择png或者jpg格式的图片。'
      })
    },
    handleMaxSize (file) {
      this.$Notice.warning({
        title: '上传文件大小异常',
        desc: '文件：  ' + file.name + ' 太大, 上传文件不能超过5M'
      })
    },
    handleBeforeUpload () {
      const check = this.uploadFiles.length < 5
      if (!check) {
        this.$Notice.warning({
          title: '不能上传超过5个主图，请删除其他图片后，重新上传！'
        })
      }
      return check
    },
    handleUploadSuccess (response, file) {
      if (response && response.success) {
        this.uploadFiles.push(file)
      }
    }
  }
}
</script>

<style scoped>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
