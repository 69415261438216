import request from '@/utils/requestV2'
// const qs = require('qs')

/**
 * 租赁空间列表
 * @param {Object} data
 */
export function spacePage (params) {
  return request.get('/ooh-product/lease-space/page', { params })
}
/**
 * 删除租赁空间
 * @param {Object} data
 */
export function deleteSpace (params) {
  return request.get('/ooh-product/lease-space/delete', { params })
}

/**
 * 租赁空间详情
 * @param {Object} data
 */
export function getSpace (params) {
  return request.get('/ooh-product/lease-space/get', { params })
}

// 新增租赁空间
export function saveSpace (data) {
  return request({
    url: '/ooh-product/lease-space/save',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: data
  })
}
// 更新租赁空间
export function updateSpace (data) {
  return request({
    url: '/ooh-product/lease-space/update',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: data
  })
}

/**
 * 租赁空间设备列表
 * @param {Object} data
 */
export function spaceDeviceList (params) {
  return request.get('/ooh-product/lease-space-device/list', { params })
}
/**
 * 租赁空间移除设备
 * @param {Object} data
 */
export function deleteSpaceDevice (params) {
  return request.get('/ooh-product/lease-space-device/delete', { params })
}

// 租赁空间新增设备
export function saveSpaceDevice (data) {
  return request({
    url: '/ooh-product/lease-space-device/save',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: data
  })
}
// 租赁空间更新设备
export function updateSpaceDevice (data) {
  return request({
    url: '/ooh-product/lease-space-device/update',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: data
  })
}
