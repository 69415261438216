<template>
  <div>
    <Row v-if="allowEdit">
          <i-col span="24" class="text-right">
            <i-button type="primary" class="m-r-5 m-t-10 m-b-5" size="small" @click="saveInvestContent"
              >保存详情</i-button
            >
          </i-col>
        </Row>
        <TinyEditor id="investDetail" v-model="investHtml" :disabled="!allowEdit" ></TinyEditor>
  </div>
</template>

<script>

import TinyEditor from '@/components/common/TinyEditor'
import { HTMLEncode } from '@/utils'

import { editInvestContent } from '@/api/invest/invest'

export default {
  components: {
    TinyEditor
  },
  props: {
    allowEdit: {
      type: Boolean,
      default: false
    },
    investId: {
      type: Number
    },
    investHtml: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {},
  methods: {
    saveInvestContent () {
      editInvestContent({
        investId: this.investId,
        content: HTMLEncode(this.investHtml)
      }).then(res => {
        this.$store.commit('set_investment_update', new Date())
      })
    }
  },
  watch: {
    beginUpdate () {
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
